<template>
  <div class="city-page" :style="{ height: screenHeight + 'px' }">
    <head-main ref="headMain"></head-main>
    <div class="city-conten-box">
      <div class="city-map">
        <div class="char-tit">
          <h5>“点击”区域显示各县市项目数据</h5>
          <h5>“双击”区域进入各县市首页</h5>
        </div>
        <ve-map
          width="100%"
          height="100%"
          :data="chartData"
          :settings="chartSettings"
          :extend="chartExtend"
          :events="chartEvents"
          :legend-visible="false"
          :tooltip-visible="false"
        ></ve-map>
        <div class="char-btn-box">
          <el-button class="char-btn1" :class="{ on: currentCityName === '总站' }" @click="setMapSeleted('总站')"
            >全市总览
          </el-button>
          <el-button
            class="char-btn2"
            :class="{ on: currentCityName === '市本级' }"
            @click="setMapSeleted('市本级')"
            @dblclick.native="toCtiyIndex('市本级')"
            >南平市(市本级)
          </el-button>
        </div>
      </div>
      <div class="system-entrance">
        <!-- 南平地区 -->
        <h5 class="data-tit">国家级省级非遗项目入口</h5>
        <h5 class="atention-tit">请点击各项目，进入各项目首页了解详情</h5>
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane :label="`国家级`" name="first">
            <!-- <el-tab-pane :label="`国家级${Object.keys(projectBatchArea1).length}项`" name="first"> -->
            <div class="tab-content">
              <div class="con-model" v-for="batch in Object.keys(projectBatchArea1)" :key="batch">
                <h5 class="tab-con-tit">{{ batch.replace('#count#', ' ') }}</h5>
                <ul class="tab-system-list">
                  <template v-for="item in projectBatchArea1[batch]">
                    <li v-for="(projectId, index) in item.projectIds.split(',')" :key="projectId">
                      <!-- <span class="sys-l">{{ item.provinceCityCountyName }}：</span> -->
                      <div class="sys-r">
                        <span class="sys-link" @click="toProject(projectId)">{{
                          item.projectNames.split(',')[index]
                        }}</span>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="`省级`" name="second">
            <!-- <el-tab-pane :label="`省级${Object.keys(projectBatchArea2).length}项`" name="second"> -->
            <div class="tab-content">
              <!-- <div class="select-box">
                <el-select v-model="value" placeholder="批次">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-model="values" placeholder="地区">
                  <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div> -->
              <div class="tab-content">
                <div class="con-model" v-for="batch in Object.keys(projectBatchArea2)" :key="batch">
                  <h5 class="tab-con-tit">{{ batch }}</h5>
                  <ul class="tab-system-list">
                    <template v-for="item in projectBatchArea2[batch]">
                      <li v-for="(projectId, index) in item.projectIds.split(',')" :key="projectId">
                        <!-- <span class="sys-l">{{ item.provinceCityCountyName }}：</span> -->
                        <div class="sys-r">
                          <span class="sys-link" @click="toProject(projectId)">{{
                            item.projectNames.split(',')[index]
                          }}</span>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="`市级`" name="three">
            <!-- <el-tab-pane :label="`市级${Object.keys(projectBatchArea3).length}项`" name="three"> -->
            <div class="tab-content">
              <div class="con-model" v-for="batch in Object.keys(projectBatchArea3)" :key="batch">
                <h5 class="tab-con-tit">{{ batch.replace('#count#', ' ') }}</h5>
                <ul class="tab-system-list">
                  <template v-for="item in projectBatchArea3[batch]">
                    <li v-for="(projectId, index) in item.projectIds.split(',')" :key="projectId">
                      <!-- <span class="sys-l">{{ item.provinceCityCountyName }}：</span> -->
                      <div class="sys-r">
                        <span class="sys-link" @click="toProject(projectId)">{{
                          item.projectNames.split(',')[index]
                        }}</span>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="`县级`" name="four">
            <!-- <el-tab-pane :label="`县级${Object.keys(projectBatchArea4).length}项`" name="four"> -->
            <div class="tab-content">
              <div class="con-model" v-for="batch in Object.keys(projectBatchArea4)" :key="batch">
                <h5 class="tab-con-tit">{{ batch.replace('#count#', ' ') }}</h5>
                <ul class="tab-system-list">
                  <template v-for="item in projectBatchArea4[batch]">
                    <li v-for="(projectId, index) in item.projectIds.split(',')" :key="projectId">
                      <!-- <span class="sys-l">{{ item.provinceCityCountyName }}：</span> -->
                      <div class="sys-r">
                        <span class="sys-link" @click="toProject(projectId)">{{
                          item.projectNames.split(',')[index]
                        }}</span>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="data-total">
        <!-- 南平地区 -->
        <h5 class="data-tit">非遗项目数据汇总</h5>
        <h5 class="total-tit">
          总计名录项目
          <span>{{ projectCount }}</span>
          (项)
        </h5>
        <ul class="total-num-list">
          <li v-for="(item, index) in projectTypeList" :key="index">
            <span class="num-span">{{ item.projectCount }}</span>
            <p class="name-span">{{ item.fintangibleTypeName }}</p>
          </li>
        </ul>
        <p class="bottom-p">数据由各非遗保护中心提供</p>
      </div>
    </div>
    <botto-com></botto-com>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
import VeMap from 'v-charts/lib/map.common'
import 'echarts/lib/component/markPoint'

export default {
  components: {
    VeMap,
    HeadMain,
    BottoCom
  },
  computed: {
    projectCount() {
      let count = 0
      this.projectTypeList.forEach(type => {
        count += type.projectCount
      })
      return count
    }
  },
  data() {
    this.chartSettings = {
      selectData: true,
      selectedMode: 'single',
      positionJsonLink: 'js/350700.json',
      position: 'nanping',
      beforeRegisterMap(json) {
        return json
      },
      labelMap: {
        //   counts: '项目数'
      },
      mapGrid: {
        left: '0%',
        right: '0%',
        top: '0%',
        bottom: '0%'
      },
      itemStyle: {
        normal: {
          //不选中样式
          borderColor: '#373635',
          borderWidth: 2, // 描边线宽。
          areaColor: '#ab9481' //背景颜色
        },
        emphasis: {
          //选中样式
          show: true
          // areaColor: "#d3a66a",
          //  label: {
          //     show: false,
          //     textStyle: {
          //       color: '#fff',
          //       fontSize: 14,
          //     }
          //   }
        },
        effect: {
          show: false,
          shadowBlur: 10,
          loop: true
        }
      },
      label: {
        //文本设置
        show: true,
        fontSize: 14,
        fontWeight: 500,
        color: '#ffffff'
      }
    }
    this.chartExtend = {
      series: {
        markPoint: {
          symbol: 'image://' + require('../../assets/images/point.png'), // 自定义图片路径
          symbolSize: [14, 16], // 图片大小
          label: {
            position: 'top',
            color: '#fff'
          },
          // coord数组存放地址坐标
          data: [
            // { name: '南平市', id: '3507' },
            { name: '光泽县', id: '350723', coord: [117.320482, 27.577273] },
            { name: '邵武市', id: '350781', coord: [117.45, 27.32] },
            { name: '顺昌县', id: '350721', coord: [117.86, 26.98] },
            { name: '延平区', id: '350702', coord: [118.13, 26.57] },
            { name: '武夷山市', id: '350782', coord: [118.0, 27.72] },
            { name: '建阳区', id: '350703', coord: [118.09, 27.37] },
            { name: '建瓯市', id: '350783', coord: [118.34, 27.01] },
            { name: '浦城县', id: '350722', coord: [118.52, 27.88] },
            { name: '松溪县', id: '350724', coord: [118.74, 27.57] },
            { name: '政和县', id: '350725', coord: [118.9, 27.35] }
          ]
        }
      }
    }
    // let timer = null
    // let cityName = null
    // let dataFlag = 0
    this.chartEvents = {
      click: v => {
        this.chartData.rows = [{ provinceName: v.name }]
        if (this.citys.length > 0) {
          this.setMapSeleted(v.name)
        } else {
          this.getCitys().then(res => {
            this.citys = res.data
            this.setMapSeleted(v.name)
          })
        }
      },
      dblclick: v => {
        let cityId = this.citys.find(city => city.columnName === v.name).id
        this.$refs.headMain.toHome(cityId)
      },
      mouseover: v => {
        // dataFlag = 0
        // cityName = v.name
        // if (cityName != null) {
        //   //不为空
        //   clearInterval(timer)
        //   timer = setTimeout(() => {
        //     //AJAX 获取数据
        //     //取到数据
        //     dataFlag = 1
        //     //地图变色，表示选中
        //     this.chartData.rows = [{ provinceName: v.name }]
        //   }, 300)
        // } else {
        //   //为空
        // }
      },
      mouseout() {
        // if (dataFlag == 0) {
        //   clearInterval(timer)
        // }
      }
    }
    return {
      currentCityName: '总站',
      cityCode: '',
      citys: [],
      chartData: {
        columns: ['provinceName', 'counts'],
        rows: []
      },
      screenHeight: document.body.clientHeight,
      activeName: 'first',
      value: '',
      values: '',
      options: [
        {
          value: '选项1',
          label: '炸鸡'
        },
        {
          value: '选项2',
          label: '可乐'
        }
      ],
      options1: [
        {
          value: '选项1',
          label: '披萨'
        },
        {
          value: '选项2',
          label: '鸡腿'
        }
      ],
      totalList: [
        { num: 23, name: '传统美术' },
        { num: 23, name: '曲艺' },
        { num: 23, name: '传统医药' },
        { num: 23, name: '传统民俗' },
        { num: 23, name: '民间文学' },
        { num: 23, name: '传统技艺' },
        { num: 23, name: '传统戏剧' },
        { num: 23, name: '传统舞蹈' },
        { num: 23, name: '传统体育、游艺与杂技' },
        { num: 23, name: '传统音乐' }
      ],
      systemList: [
        {
          tit: '第一批：共3个，2006年5月公布',
          array: [
            {
              city: '南平市',
              val: '南平南词'
            },
            {
              city: '武夷山市',
              val: '武夷岩茶（大红袍）制作技艺'
            },
            {
              city: '政和县',
              val: '四平戏（政和）'
            }
          ]
        },
        {
          tit: '第二批：共2个，2008年6月公布',
          array: [
            { city: '邵武市', val: '傩舞（邵武傩舞）' },
            { city: '建瓯市', val: '中幡（建瓯挑幡）' }
          ]
        },
        {
          tit: '第三批：共1个，2011年5月公布',
          array: [{ city: '南平市', val: '建窑建盏烧制技艺' }]
        },
        {
          tit: '第四批：共1个，2014年11月公布',
          array: [{ city: '浦城县', val: '剪纸（浦城剪纸）' }]
        }
      ],
      systemList1: [
        {
          tit: '第一批：共14项， 2005年10月公布',
          array: [
            {
              city: '延平区',
              val: ['延平塔前大腔金线傀儡', '延平闽蛇崇拜民俗', '延平蛙崇拜民俗', '延平战胜鼓']
            },
            {
              city: '建阳市',
              val: ['建阳建本雕版印刷']
            },
            {
              city: '邵武市',
              val: ['邵武长门', '邵武三角戏']
            },
            {
              city: '浦城县',
              val: ['浦城闽派古琴']
            }
          ]
        },
        {
          tit: '第二批：共2项， 2007年8月公布',
          array: [{ city: '邵武市', val: ['邵武河坊抢酒节'] }]
        },
        {
          tit: '第三批：共7项， 2009年5月公布',
          array: [{ city: '建瓯市', val: ['建瓯唱曲子、弓鱼技艺'] }]
        }
      ],
      projectTypeList: [],
      projectBatchArea1: [],
      projectBatchArea2: [],
      projectBatchArea3: [],
      projectBatchArea4: []
    }
  },
  mounted() {
    this.getCitys().then(res => {
      this.citys = res.data
    })
    this.getProjectTypeList(1)
    this.getProjectBatchArea(1)
    this.getProjectBatchArea(2)
    this.getProjectBatchArea(3)
    this.getProjectBatchArea(4)
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight
        that.screenHeight = window.screenHeight
      })()
    }
  },
  watch: {
    // '$route.query.city': {
    //   handler(cityId) {
    //     if (this.$refs.headMain) this.$refs.headMain.getMenus()
    //     // this.getCityMapInfo()
    //     this.getProjectTypeList()
    //     this.getProjectBatchArea(1)
    //     this.getProjectBatchArea(2)
    //     this.getProjectBatchArea(3)
    //     this.getProjectBatchArea(4)

    //     this.setMapSeleted()
    //   },
    //   immediate: true
    // },
    screenHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenHeight = val
        this.timer = true
        let that = this
        setTimeout(function() {
          // 打印screenWidth变化的值
          console.log(that.screenHeight)
          that.timer = false
        }, 400)
      }
    }
  },
  methods: {
    toCtiyIndex(name) {
      let cityId = this.citys.find(city => city.columnName === name).id
      this.$refs.headMain.toHome(cityId)
    },
    getCityMapInfo() {},
    getCitys() {
      return this.axios.get('/api/portal/site/map', {})
    },
    setMapSeleted(cityName) {
      this.activeName = 'first'
      this.currentCityName = cityName
      this.chartData.rows = []
      let city = this.citys.find(city => city.columnName === cityName)
      this.cityCode = city.provinceCityCounty == 35 ? '' : city.provinceCityCounty
      if (city) {
        this.chartData.rows = [{ provinceName: cityName }]
      }
      this.getProjectTypeList(1)
      this.getProjectBatchArea(1)
      this.getProjectBatchArea(2)
      this.getProjectBatchArea(3)
      this.getProjectBatchArea(4)
    },
    getProjectTypeList(batchType) {
      this.axios
        .get('/api/portal/report/project-type', {
          params: {
            batchType: batchType,
            provinceCityCounty: this.cityCode
          }
        })
        .then(res => {
          this.projectTypeList = res.data
        })
    },
    getProjectBatchArea(batchType) {
      this.axios
        .get('/api/portal/report/project-batch-area', {
          params: {
            batchType: batchType,
            provinceCityCounty: this.cityCode
          }
        })
        .then(res => {
          this['projectBatchArea' + batchType] = res.data
        })
    },
    handleTabClick(tab) {
      this.getProjectTypeList(Number(tab.index) + 1)
    },
    toProject(projectId) {
      this.axios
        .get(`/api/portal/cont/pub-list`, { params: { contentId: projectId, contentType: 3, size: 1 } })
        .then(res => {
          let { contentUrl, id, contentId } = res.data.records[0]
          this.$router.push({ path: contentUrl, query: { id, contentId } })
        })
    }
  }
}
</script>

<style lang="scss">
.el-select-dropdown {
  border: 0px solid #e4e7ed;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.18);
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: transparent;
  color: #ffd7a1;
}

.el-select-dropdown__item {
  font-size: 14px;
  padding: 0 10px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}
.el-popper .popper__arrow::after {
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: rgba(0, 0, 0, 0.18) !important;
}
</style>
